import React from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"

import Layout from "../../layout"

const OurMission = () => {
  return (
    <>
      <Layout hideFooter={true}>
      
        {/* <section className="job-contents p-top-20 p-bottom-65">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
             
              
              </div>
            </div>
          </div>
        </section> */}

        <div id="webterminal" style={{width: '100%', height: '80vh'}}></div>
              <Helmet>
                    {/* <script src='https://trade.mql5.com/trade/widget.js'></script> */}
                    {/* <script>try{Typekit.load({ async: true });}catch(e){}</script> */}
                    
                    <script type="text/javascript">
                    {/* {`
                    setTimeout(() => {
                        new MetaTraderWebTerminal( "webterminal", {
                        version: 5,
                        server: "MetaQuotes-Demo",
                        demoAllServers: true,
                        startMode: "create_demo",
                        lang: "en",
                        colorScheme: "black_on_white"
                    } );
                }, 1000);
                `} */}
                {`
                    setTimeout(() => {
                    new MetaTraderWebTerminal( "webterminal", { 
                        version: 4, 
                        servers: ["PCGGlobal-Live"], 
                        server: "PCGGlobal-Live", 
                        demoAllServers: false, 
                        utmCampaign: "", 
                        utmSource: "www.pcgmarkets.com", 
                        startMode: "login", 
                        language: "en", 
                        colorScheme: "black_on_white" 
                    } );
                }, 1000);
                `}
                    </script>
                </Helmet>

        {/* <Subscription /> */}
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
